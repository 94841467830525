<template>
    <div class="attraction">
        <h2 class="activity-name">{{ attraction.display_title }}</h2>

        <div v-if="booking.attractions[attraction.id] && booking.attractions[attraction.id].products">
            <product
                v-for="(product, idx) in [...packageProducts, ...nonPackageProducts]"
                :key="product.display_title + idx"
                :product="product"
                :associatedVoucher="booking.vouchers[product.voucher_index]"
                @product-clicked="handleBookingSelection(attraction, product)"
                :data-cy="`product-${idx}`"
            />

            <template v-if="[...packageProducts, ...nonPackageProducts].length">
                <div v-for="(addon, idx) in attraction.addons" :key="'addon' + idx" class="addon" :data-cy="`addon-${idx}`">
                    <div class="flex">
                        <label class="clickable">
                            <div class="checkbox">
                                <input
                                    type="checkbox"
                                    v-model="booking.attractions[attraction.id].addons[addon.id].isAdded"
                                    @change="handleAddonToggled(attraction.id, addon.id)"
                                />
                                <span />
                            </div>
                            <div>
                                Add '{{ addon.display_title }}' (<span class="currency">${{ addon.price }}</span
                                >/p{{ addon.per == 'participant' && 'p' }})
                            </div>
                        </label>

                        <select
                            v-model="booking.attractions[attraction.id].addons[addon.id].quantity"
                            :disabled="!booking.attractions[attraction.id].addons[addon.id].isAdded"
                            class="border ml-auto border-gray-400 py-0 rounded-md"
                        >
                            <option v-for="(qty, i) of [...new Array(maxPeople)]" :key="qty" :value="i + 1">
                                {{ i + 1 }}
                            </option>
                        </select>
                        <span
                            :class="!booking.attractions[attraction.id].addons[addon.id].isAdded && 'opacity-0'"
                            class="currency font-bold ml-3 flex-shrink-0"
                            >${{ addon.price * booking.attractions[attraction.id].addons[addon.id].quantity }}
                        </span>
                    </div>

                    <p class="mt-2 ml-6 pl-2 text-gray-700">{{ addon.promo_description }}</p>
                </div>
            </template>
        </div>

        <button class="link ml-2" @click="handleBookingSelection(attraction)" data-cy="btn-add">+ Add {{ attraction.product_noun }}</button>
    </div>
</template>

<script>
import ScheduledAttractionModal from '../../Modals/ScheduledAttractionModal.vue';
import CreditAttractionModal from '../../Modals/CreditAttractionModal.vue';
import Product from './Attraction/Product.vue';

export default {
    store: ['config', 'booking'],

    components: { Product },

    props: ['attraction'],

    data() {
        return {
            addonQuantity: this.maxPeople
        };
    },

    mounted() {
        // let whatever = this.attraction.addons.map(addon => {
        //     return {
        //         id: addon.id,
        //         quantity: 0
        //     }
        // });
        // let whatever = this.attraction.addons.reduce((addonObject, addon) => {
        //     addonObject[addon.id] = 0;
        //     return addonObject
        // }, {});
    },

    computed: {
        packageProducts() {
            return this.booking.attractions[this.attraction.id].products.filter(product => product.voucher_index !== undefined);
        },

        nonPackageProducts() {
            return this.booking.attractions[this.attraction.id].products.filter(product => product.voucher_index == undefined);
        },

        maxPeople() {
            return this.booking.attractions[this.attraction.id].products.reduce((total, product) => {
                return total + product.max_people * product.quantity;
            }, 0);
        }
    },

    methods: {
        handleAddonToggled(attractionId, addonId) {
            const addon = this.booking.attractions[attractionId].addons[addonId];
            if (addon.isAdded) {
                addon.quantity = 1;
            } else {
                addon.quantity = 0;
            }
        },

        handleBookingSelection(attraction, product) {
            if (attraction.type == 'scheduled') {
                this.showScheduledAttractionModal(attraction, product);
            } else if (attraction.type == 'credit') {
                this.showCreditAttractionModal(attraction, product);
            }
        },

        async showScheduledAttractionModal(attraction, product) {
            let selection = await this.$modal(ScheduledAttractionModal, {
                booking: this.booking,
                attraction: attraction,
                product: product
            });

            if (!selection) return;

            this.handleProductSelection(attraction, product, selection);
        },

        async showCreditAttractionModal(attraction, product) {
            let selection = await this.$modal(CreditAttractionModal, {
                booking: this.booking,
                attraction: attraction,
                product: product
            });

            this.handleProductSelection(attraction, product, selection);
        },

        handleProductSelection(attraction, product, selection) {
            // bail if no selection was made or changed
            if (!selection) return;

            // if a selection was made, and the attraction has not been added to booking, add it.
            let currentAttraction = this.booking.attractions[attraction.id];

            if (!currentAttraction)
                currentAttraction = this.booking.attractions[attraction.id] = {
                    display_title: attraction.display_title,
                    type: attraction.type,
                    products: [],
                    addons: attraction.addons.reduce((addonObject, addon) => {
                        addonObject[addon.id] = {
                            ...addon,
                            isAdded: false,
                            quantity: 0
                        };
                        return addonObject;
                    }, {})
                };

            let attractionProducts = currentAttraction.products;
            let existingProduct = attractionProducts.find(product => product.id == selection.id && product.voucher_index == undefined);

            // if product was clicked, check whether to delete product or update quantity
            if (product) {
                if (selection.quantity == 0 || selection.quantity == null) {
                    // regardless of product change, if quantity is 0 or null, delete product that was clicked.
                    attractionProducts.splice(
                        attractionProducts.findIndex(x => x.id == selection.id && product.voucher_index == undefined),
                        1
                    );
                    return;
                }
                if (selection.quantity > 0) {
                    let productIdx = attractionProducts.findIndex(x => x.id == product.id && product.voucher_index == undefined);
                    this.booking.attractions[attraction.id].products.splice(productIdx, 1, { ...selection, quantity: selection.quantity });
                }
            }

            // if + add button clicked but existing product was found, check whether to add to the quantity, or do nothing.
            else if (existingProduct) {
                if (selection.quantity != 0) existingProduct.quantity += selection.quantity;
            }

            // if no product clicked, and no matching existing product found, add selection to booking
            // this.booking.attractions[attraction.id].products.splice(-1, 0, selection);
            else this.booking.attractions[attraction.id].products.push(selection);
        },

        showAddonDescription(addon) {
            this.$alert(addon.display_title, addon.promo_description);
        }
    }
};
</script>

<style lang="scss" scoped>
.attraction {
    @apply py-6;

    &:first-of-type {
        @apply pt-0;
    }

    .activity-name {
        @apply text-lg font-bold;
    }

    .addon {
        @apply flex flex-col bg-white mb-2 p-2 border border-gray-400 rounded-md;

        p {
            @apply flex-1 mr-2 mb-2;
        }

        > div:nth-child(2) {
            @apply ml-auto;
        }
    }
}
</style>
