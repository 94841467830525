<template>
    <modal id="booking-scheduled-activity-modal" closeX closeOnMaskClick scrolls>
        <template v-slot:header
            ><h1>Add {{ attraction.product_noun.ucwords() }}</h1></template
        >

        <div class="message">do the diddly darn thing blah blah blah</div>

        <div class="block-buttons">
            <template v-for="(product, index) of attraction.products">
                <button
                    v-if="product.max_people"
                    class="block"
                    :class="{ selected: selectedProduct == product }"
                    :key="index"
                    @click="selectProduct(product)"
                    :data-cy="`product-${index}`"
                >
                    <span class="title"> {{ product.minutes }} minutes </span>
                    <span class="tagline">
                        {{ product.max_people > 1 ? `Can be shared with ${product.max_people} people` : '' }}
                    </span>
                    <span class="price"> ${{ product.price }} </span>
                </button>
            </template>
        </div>
        <template v-slot:footer>
            <button v-if="this.product" class="destructive" @click="deleteProduct" data-cy="btn-remove">Remove</button>
            <div class="right">
                <number-input :value="quantity" class="mr-2" @input="newValue => (quantity = newValue)" />
                <button class="primary" @click="confirm" :disabled="!selectedProduct || (!product && quantity === 0)" data-cy="btn-update">
                    {{ buttonMessage }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import NumberInput from '../Components/NumberInput.vue';

export default {
    components: { NumberInput },

    data() {
        return {
            quantity: 1,
            buttonMessage: 'Add',
            selectedProduct: null
        };
    },

    mounted() {
        if (this.product != null) {
            this.selectedProduct = this.attraction.products.find(product => product.id == this.product.id);
            //  console.log(this.product.quantity)
            this.quantity = this.product.quantity;
            this.buttonMessage = 'Update';
        }
    },

    methods: {
        selectProduct(product) {
            this.selectedProduct = product;
        },

        deleteProduct() {
            this.$dismiss({ ...this.product, quantity: 0 });
        },

        confirm() {
            if (this.selectedProduct == null || this.quantity == null) return;

            this.$dismiss({
                ...this.selectedProduct,
                quantity: this.quantity
            });
        }
    }
};
</script>
<style lang="scss">
#booking-scheduled-activity-modal {
    .vf-modal {
        @apply flex justify-center overflow-auto max-w-5xl;
    }

    .message {
        @apply p-4 pt-0 flex justify-center;
    }

    .block-buttons {
        @apply flex justify-center flex-wrap leading-tight;

        .block {
            @apply flex flex-col items-center justify-center m-2 p-0 md:p-6 w-full md:w-48 h-32 md:h-40 bg-gray-100 border border-gray-500 duration-75 shadow-sm;

            .title {
                @apply text-lg md:text-2xl;
            }

            .tagline {
                @apply p-2 text-base;
            }

            .price {
                @apply text-lg md:text-2xl;
            }

            &:hover {
                @apply bg-blue-lightest shadow-md;
            }

            &.selected {
                @apply bg-blue text-white shadow-md;
            }
        }
    }

    .vf-modal-footer {
        @apply flex;

        .right {
            @apply flex ml-auto;

            input {
                @apply border border-gray-500 w-20 p-2;
            }
        }
    }
}
</style>
