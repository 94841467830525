<template>
    <nav v-if="store.session?.config">
        <img v-if="store.session.config.logo" :src="store.session.config.logo" />
        <span v-else>{{ store.session.config.businessName }}</span>

        <h3>{{ title }}</h3>
    </nav>
</template>

<script lang="ts" setup>
import { useStore } from '../store';

const store = useStore();

defineProps<{
    title: string;
}>();
</script>

<style lang="scss" scoped>
nav {
    @apply flex items-center justify-between mb-8;
}

h3 {
    @apply font-semibold text-2xl ml-auto text-right;
}
</style>
