<template>
    <div id="booking-overview">
        <section>
            <header>
                <h1 data-cy="header-pkgs">Packages &amp; Deals</h1>
            </header>
            <div class="flex-row-center space-x-4">
                <button class="btn-voucher" @click="showPackageModal" data-cy="btn-voucher-pkg">
                    <i class="fas fa-plus mb-2" />
                    Add Package
                </button>

                <button class="btn-voucher rounded-md hover:border-gray-400 hover:bg-red-500" @click="showVoucherModal" data-cy="btn-voucher-gc">
                    <i class="fas fa-plus mb-2" />
                    Gift card or <br />
                    online deal voucher
                </button>
            </div>
            <div v-if="booking.vouchers.length" class="voucher-list flex-col-center space-y-2 mt-4">
                <button
                    v-for="(voucher, index) in booking.vouchers"
                    :key="voucher + index"
                    class="voucher"
                    @click="showPackageModal"
                    :data-cy="`voucher-${index}`"
                >
                    <span v-if="voucher.quantity" class="quantity">{{ voucher.quantity }}</span>
                    {{ voucher.display_title }}
                    <div class="right ml-auto">
                        <span v-if="voucher.price" class="currency" data-cy="price">${{ voucher.price * voucher.quantity }}</span>
                        <button
                            data-cy="btn-remove"
                            class="remove btn square-small ml-3 border border-gray-300 hover:text-white hover:bg-red-500 focus:ring-red-500 hover:shadow-md text-gray-600 hover:text-white"
                            @click.stop="removeVoucher(voucher, index)"
                        >
                            <span class="text-lg"><i class="fas fa-times" /></span>
                        </button>
                    </div>
                </button>
            </div>
        </section>

        <section class="mt-4">
            <header>
                <h1 data-cy="header-activities">Activities</h1>
            </header>

            <div class="activities flex-col divide-y divide-gray-300">
                <attraction
                    v-for="(attraction, idx) in config.attractions"
                    :key="attraction.id"
                    :attraction="attraction"
                    :booking="booking"
                    :config="config"
                    :data-cy="`attraction-${idx}`"
                />
            </div>

            <div class="total rounded-md">
                <span>Total</span>
                <span class="ml-auto" :class="{ currency: booking.total }" data-cy="total"
                    >{{ booking.total != 0 ? `$${booking.total}` : `&mdash;` }}
                </span>
            </div>
        </section>

        <section class="footer">
            <button data-cy="btn-continue" class="confirm primary" :disabled="!verifySelections()" @click="confirm">Continue</button>
        </section>
    </div>
</template>

<script>
import Attraction from '../../Components/Overview/Attraction.vue';
import PackageModal from '../../Modals/PackageModal.vue';

export default {
    name: 'Overview',

    store: ['config', 'booking'],

    components: { Attraction },

    data() {
        return {};
    },

    watch: {
        booking: {
            deep: true,
            handler() {
                let total = 0;

                // loop through attractions
                for (const attraction in this.booking.attractions) {
                    // loop through products
                    if (this.booking.attractions[attraction].products.length === 0) continue;

                    this.booking.attractions[attraction].products.forEach(product => {
                        // if product has voucherIndex, it has a discounted price, so skip it.
                        if (product.voucher_index !== undefined) return;
                        total += (product.discounted ? product.discounted_price : product.price) * product.quantity;
                    });

                    for (const addonId in this.booking.attractions[attraction].addons) {
                        let addon = this.booking.attractions[attraction].addons[addonId];
                        if (!addon.isAdded) total += 0;
                        else total += addon.price * addon.quantity;
                    }
                }

                this.booking.vouchers.forEach(voucher => {
                    total += voucher.price * voucher.quantity;
                });

                this.booking.total = total;
            }
        }
    },

    methods: {
        /***** PACKAGES AND VOUCHERS *****/

        async showPackageModal() {
            let aPackage = await this.$modal(PackageModal, {
                message:
                    'Surf in a 15-minute group surf session, get one wet and wild ride in the Fishpipe, soar to new heights in the wind tunnel with a 2-minute individual flight, and climb our rock wall for 15 minutes.',
                config: this.config,
                booking: this.booking
            });

            if (!aPackage) return;

            this.handleVoucherUpdate(aPackage);
        },

        async showVoucherModal() {
            // show user temporary modal until this is implemented

            this.$alert('Gift Cards & Vouchers', 'Not available online yet. \nTo use a gift card or voucher, please call or walk in to book.');

            // let voucher = await this.$modal(VoucherModal, {
            //     closeOnMaskClick: true,
            //     message: "Enter your Groupon or gift card code below."
            // });

            // if (voucher)
            //     this.booking.vouchers.push(voucher);
        },

        async handleVoucherUpdate(updatedVoucher) {
            // check for existing vouchers/packages
            let existingVoucherIdx = this.booking.vouchers.findIndex(voucher => voucher.id == updatedVoucher.id);
            let existingVoucher = this.booking.vouchers[existingVoucherIdx];

            // create placeholder for voucher index.
            // we will attach this index to products we add to associate with voucher.
            let newVoucherIndex;

            // no existing voucher/package found, add it to booking
            if (!existingVoucher) {
                this.booking.vouchers.push(updatedVoucher);
                newVoucherIndex = this.booking.vouchers.length - 1;
            }

            // existing voucher/package found, check whether to update quantity or remove it from booking
            else if (existingVoucher) {
                if (updatedVoucher.quantity <= 0) {
                    const idx = this.booking.vouchers.findIndex(voucher => voucher.id == updatedVoucher.id);
                    this.booking.vouchers.splice(idx, 1);
                    this.removeVoucherProducts(idx);

                    return;
                } else {
                    existingVoucher.quantity = updatedVoucher.quantity;
                }
            }

            // loop products in voucher, and add them to booking.
            for (const productId in updatedVoucher.included_products) {
                let discounted_price = updatedVoucher.included_products[productId];
                let productDetails;

                for (const attraction of this.config.attractions) {
                    productDetails = attraction.products.find(product => product.id == productId);
                    if (!productDetails) continue;

                    this.handleProductSelection(attraction, null, {
                        ...productDetails,
                        quantity: updatedVoucher.quantity,
                        voucher_index: existingVoucherIdx < 0 ? newVoucherIndex : existingVoucherIdx,
                        discounted_price
                    });
                }
            }
        },

        removeVoucher(voucher) {
            let idx = this.booking.vouchers.findIndex(existingVoucher => existingVoucher.id == voucher.id);
            this.booking.vouchers.splice(idx, 1);
            this.removeVoucherProducts(idx);
        },

        removeVoucherProducts(idx) {
            for (const attraction in this.booking.attractions) {
                let i = 0;
                while (i < this.booking.attractions[attraction].products.length)
                    if (this.booking.attractions[attraction].products[i].voucher_index === idx)
                        this.booking.attractions[attraction].products.splice(i, 1);
                    else ++i;
            }
        },

        /***** ATTRACTION SELECTIONS *****/

        handleProductSelection(attraction, product, selection) {
            // bail if no selection was made or changed
            if (!selection) return;

            // if a selection was made, and the attraction has not been added to booking, add it.
            let currentAttraction = this.booking.attractions[attraction.id];

            if (!currentAttraction) {
                currentAttraction = this.booking.attractions[attraction.id] = {
                    display_title: attraction.display_title,
                    type: attraction.type,
                    products: [],
                    addons: attraction.addons.reduce((addonObject, addon) => {
                        addonObject[addon.id] = {
                            ...addon,
                            isAdded: false,
                            quantity: 0
                        };
                        return addonObject;
                    }, {})
                };
            }

            let attractionProducts = currentAttraction.products;
            let existingProduct = attractionProducts.find(product => (product.id = selection.id && product.voucher_index == selection.voucher_index));

            // if product was clicked, check whether to delete product or update quantity
            if (product) {
                if (selection.quantity == 0 || selection.quantity == null) {
                    // regardless of product change, if quantity is 0 or null, delete product that was clicked.
                    attractionProducts.splice(
                        attractionProducts.findIndex(x => x.id == selection.id),
                        1
                    );
                    if (!attractionProducts.length) this.toggleAttractionSelection(attraction);
                    return;
                }
                if (selection.quantity > 0) {
                    let productIdx = attractionProducts.findIndex(x => x.id == product.id);
                    this.booking.attractions[attraction.id].products.splice(productIdx, 1, { ...selection, quantity: selection.quantity });
                }
            }

            // if + add button clicked but existing product was found, check whether to add to the quantity, or do nothing.
            else if (existingProduct) {
                if (selection.quantity != 0) {
                    //  console.log(existingProduct, selection);
                    existingProduct.quantity = selection.quantity;
                }
            }

            // if no product clicked, and no matching existing product found, add selection to booking
            else this.booking.attractions[attraction.id].products.splice(-1, 0, selection);
        },

        /***** CHECKS *****/

        verifySelections() {
            for (const attraction in this.booking.attractions) {
                if (this.booking.attractions[attraction].products.length === 0) continue;

                for (let product in this.booking.attractions[attraction].products)
                    if (this.booking.attractions[attraction].products[product].quantity !== 0) return true;

                for (const addon in this.booking.attractions[attraction].addons)
                    if (this.booking.attractions[attraction].addons[addon].quantity !== 0) return true;
            }

            return false;
        },

        confirm() {
            this.$emit('push');
        }
    }
};
</script>

<style scoped lang="scss">
#booking-overview {
    @apply flex flex-col;

    section:not(.footer) {
        @apply flex flex-col justify-center;

        header {
            @apply flex justify-center m-4;

            h1 {
                @apply flex items-center justify-center max-w-sm h-1 font-bold text-xl text-blue my-4;
            }
        }
    }

    .btn-voucher {
        @apply flex flex-col items-center justify-center h-32 p-4 bg-white transition duration-150 border border-solid border-gray-500 w-full max-w-xs text-gray-600 shadow-md;

        &:hover,
        &:focus {
            @apply border-blue-darker text-gray-800 shadow-lg;
        }
    }

    .voucher {
        @apply flex items-center m-2 p-2 w-full bg-white border-solid border border-gray-400 rounded-md hover:shadow-sm hover:bg-blue-lightest hover:border-blue;

        .quantity {
            @apply mr-2 bg-blue text-white h-6 min-w-6 px-2 flex items-center justify-center rounded-full;
        }
    }

    .total {
        @apply flex p-4 text-xl bg-blue text-white font-bold;
    }
}
</style>
