<template>
    <div class="date-input">
        <select v-model="year" name="year" :required="required">
            <option selected disabled value="">Year</option>
            <option v-for="(year, i) in years" :key="i" :value="year">
                {{ year }}
            </option>
        </select>
        <select v-model="month" name="month" :required="required">
            <option selected disabled value="">Month</option>
            <option v-for="(month, i) in months" :key="i" :value="i + 1">
                {{ month }}
            </option>
        </select>
        <select v-model="day" name="day" :required="required">
            <option selected disabled value="">Day</option>
            <option v-for="(day, i) in daysInMonth" :key="i" :value="day">{{ day }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: ['modelValue', 'minYearOffset', 'maxYearOffset', 'required'],

    data() {
        return {
            year: '',
            month: '',
            day: ''
        };
    },

    watch: {
        date(val) {
            this.$emit('update:modelValue', val);
        },

        modelValue() {
            this.updateValues(this.modelValue);
        }
    },

    created() {
        this.updateValues(this.modelValue);
    },

    computed: {
        years() {
            const thisYear = new Date().getFullYear();
            const minYear = thisYear - this.minYearOffset;
            const maxYear = thisYear - this.maxYearOffset;
            const diff = maxYear - minYear + 1;
            return [...Array(diff).keys()].map(x => minYear + x).reverse();
        },

        months() {
            return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        },

        numberOfDaysInMonth() {
            const isLeapYear = this.year % 4 === 0;
            return [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][this.month - 1];
        },

        daysInMonth() {
            if (!this.month) return 0;
            return [...Array(this.numberOfDaysInMonth).keys()].map(x => x + 1);
        },

        date() {
            if (!this.day || !this.month || !this.year) return null;
            return `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`;
        }
    },

    methods: {
        updateValues(dateString) {
            if (dateString) {
                const matches = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
                if (matches) {
                    this.year = parseInt(matches[1]);
                    this.month = parseInt(matches[2]);
                    this.day = parseInt(matches[3]);
                    return;
                }
            }

            this.year = '';
            this.month = '';
            this.day = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.date-input {
    @apply w-full flex space-x-3;

    select {
        @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm rounded-md;
    }
}
</style>
