<template>
    <div id="booking">
        <AppHeader title="Online Booking"></AppHeader>

        <keep-alive>
            <component
                v-if="config"
                :is="activeComponent"
                :key="activeComponent"
                @component-changed="setActiveComponent"
                @pop="pop"
                @push="push"
                @session-selected="updatePackage"
                class="view-wrapper"
            />

            <div v-else class="loading-indicator">
                <i class="fad fa-spinner-third fa-spin" />
                <span class="message">Loading...</span>
            </div>
        </keep-alive>
    </div>
</template>

<script>
import Overview from './Booking/Overview.vue';
import Calendar from './Booking/Calendar.vue';
import Terms from './Booking/Terms.vue';
import Checkout from './Booking/Checkout.vue';
import Participants from './Booking/Participants.vue';
import AppHeader from '../../../components/Header.vue';

const BookingComponents = [Overview, Calendar, Terms, Checkout, Participants];

export default {
    components: {
        AppHeader,
        ...BookingComponents
    },

    store: ['config', 'booking'],

    data() {
        return {
            store: {
                config: null,
                booking: {
                    attractions: {},
                    vouchers: [],
                    total: 0
                }
            },
            components: Object.keys(BookingComponents),
            activeComponentIdx: 0
        };
    },

    computed: {
        activeComponent() {
            return this.components[this.activeComponentIdx];
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            try {
                let result = await this.$http.get('/php-api/booking/config');
                this.config = result.data;
            } catch (err) {
                this.$alert('error', err);
            }
        },

        setActiveComponent(component) {
            this.activeComponent = component;
        },

        pop() {
            if (this.activeComponentIdx == 0) return;
            this.activeComponentIdx--;
        },

        push() {
            this.activeComponentIdx++;
        },

        updatePackage(session) {
            let activity;

            if (session) activity = this.booking[session.activity];

            activity.selected = true;
            activity.partySize = session.party;
            activity.time = session.time;
            activity.sessionType = session.sessionType;

            this.activeComponent = 'overview';
        }
    }
};
</script>

<style lang="scss">
#booking {
    @apply flex-1 min-h-0 lg:px-6 xl:px-24 overflow-auto;

    @screen lg {
        .view-wrapper {
            @apply max-w-screen-xl;
        }
    }

    .loading-indicator {
        @apply flex-1 flex flex-col items-center justify-center text-blue m-6;
        user-select: none;

        i {
            @apply text-5xl mb-8;
        }

        .message {
            @apply text-xl;
            animation: blink-fade 1.5s infinite;

            @keyframes blink-fade {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }

    section.footer {
        @apply mt-4 w-full flex justify-end space-x-4;
    }
}
</style>
