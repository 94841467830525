<template>
    <button
        :class="['product', associatedVoucher && 'non-removable']"
        v-tip="associatedVoucher && 'This is part of a package. To remove it, remove the package.'"
        @click="handleProductClicked()"
    >
        <span class="quantity" data-cy="quantity">{{ product.quantity }}</span>

        <div class="flex flex-col lg:flex-row">
            <span class="text-left mr-1">
                {{ product.display_title }}
            </span>
            <strong v-if="associatedVoucher" class="text-left">({{ associatedVoucher.display_title }})</strong>
        </div>

        <span class="ml-auto font-bold" :class="['currency', { discounted: product.discounted_price }]">${{ product.price * product.quantity }}</span>
        <span v-if="associatedVoucher" class="ml-3 font-bold currency">${{ product.discounted_price * product.quantity }}</span>
    </button>
</template>

<script>
export default {
    props: ['attraction', 'product', 'associatedVoucher'],

    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) return true;
            else return false;
        },
        handleProductClicked() {
            if (this.isMobile() && this.associatedVoucher) this.showNotRemovableAlert();
            else if (this.associatedVoucher) return;
            else this.$emit('product-clicked');
        },

        showNotRemovableAlert() {
            this.$alert(
                'Cannot Change',
                "This item is part of a package and cannot be changed or removed. To remove it, remove the package it's part of."
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.product {
    @apply flex mt-2 mb-2 p-2 w-full bg-white border border-solid border-gray-400 hover:border-blue hover:bg-blue-lightest focus:bg-blue-lightest shadow-sm hover:shadow-sm;

    &.non-removable {
        @apply hover:bg-white focus:bg-white hover:border-gray-400 focus:ring-0 cursor-default;
    }

    .quantity {
        @apply mr-2 bg-blue text-white h-6 min-w-6 px-2 flex items-center justify-center rounded-full;
    }

    .discounted {
        @apply font-normal line-through text-gray-500;
    }
}
</style>
