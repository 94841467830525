<template>
    <div id="booking-checkout">
        <section class="main">
            <section class="reservation">
                <div>
                    <h2>Your Reservation</h2>

                    <div v-for="voucher in booking.vouchers" :key="voucher.id" class="voucher">
                        <div class="flex block mb-2">
                            {{ voucher.quantity }} x {{ voucher.display_title }}
                            <span class="currency ml-auto">{{ $filter(voucher.price, 'usCurrency') }}</span>
                        </div>
                        <span v-for="product in getVoucherProducts(voucher)" :key="product.id + 'Voucher'" class="product pl-2 text-gray-600">
                            &dash; {{ product.display_title }}
                        </span>
                    </div>

                    <template v-for="attraction in booking.attractions">
                        <div
                            v-for="product in attraction.products.filter(product => product.voucher_index == undefined)"
                            :key="product.id"
                            class="product"
                        >
                            {{ product.quantity }} x {{ product.display_title }}
                            <span class="currency ml-auto">{{ $filter(product.price, 'usCurrency') }}</span>
                        </div>
                    </template>

                    <div class="total">
                        Total
                        <span class="currency ml-auto"> ${{ booking.total }}</span>
                    </div>
                </div>
            </section>

            <form v-if="booking.customer" class="form">
                <h1>Personal Information</h1>

                <div class="row">
                    <div class="input">
                        <label>First Name</label>
                        <input v-model="booking.customer.first_name" type="text" />
                    </div>

                    <div class="input">
                        <label>Last Name</label>
                        <input v-model="booking.customer.last_name" type="text" />
                    </div>
                </div>

                <div class="row">
                    <div class="input phone-number">
                        <label>Phone Number</label>
                        <input v-model="booking.customer.phone_number" type="text" />
                    </div>

                    <div class="input email">
                        <label>E-mail Address</label>
                        <input v-model="booking.customer.email_address" type="text" />
                    </div>
                </div>

                <div class="row">
                    <div class="input zip">
                        <label>Zip Code</label>
                        <input v-model="booking.customer.zip_code" type="text" />
                    </div>

                    <div class="input reference">
                        <label>How did you hear about us?</label>
                        <input v-model="booking.customer.reference" type="text" />
                    </div>
                </div>

                <h1>Payment Information</h1>

                <div class="payment-container">
                    <div v-if="isApplePayAvailable" class="apple-pay-button apple-pay-button-black h-12 w-1/2" @click="createApplePayRequest"></div>

                    <div v-if="isApplePayAvailable" class="divider">
                        <hr />
                        <span>or</span>
                    </div>

                    <div class="space-x-2 text-5xl leading-none mb-2">
                        <i class="fab fa-cc-visa text-blue-darkest"></i>
                        <i class="fab fa-cc-mastercard text-red-600"></i>
                        <i class="fab fa-cc-discover text-yellow-600"></i>
                        <i class="fab fa-cc-amex text-blue-darker"></i>
                    </div>

                    <div class="card-details">
                        <input v-model="booking.customer.cc_number" class="number" type="text" name="card-number" placeholder="1234 5678 9012 3456" />

                        <input
                            v-model="booking.customer.cc_expiration"
                            class="expiration"
                            type="text"
                            name="card-expiration"
                            placeholder="MM / YY"
                            size="8"
                        />

                        <input v-model="booking.customer.cc_security" class="security" type="text" name="card-security" placeholder="CVC" size="4" />
                    </div>
                </div>
            </form>
        </section>

        <section class="footer">
            <button class="back default" @click="$emit('pop')">Back</button>
            <div v-if="selectedPaymentMethod == 'applePay'" class="apple-pay-button apple-pay-button-black" @click="createApplePayRequest"></div>
            <button class="confirm primary" :disabled="!booking.selected.date || !booking.selected.slot" @click="confirm">Confirm &amp; Pay</button>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Checkout',

    store: ['config', 'booking'],

    data() {
        return {
            isLoading: true,
            isApplePayAvailable: false,
            selectedPaymentMethod: 'card',
            selected: {
                date: null,
                slot: null
            }
        };
    },

    mounted() {
        this.configureApplePay();

        this.booking.customer = {
            first_name: null,
            last_name: null,
            date_of_birth: null,
            phone_number: null,
            email_address: null,
            cc_number: null,
            cc_expiration: null,
            cc_security: null,
            cc_name: null,
            cc_zip: null
        };
    },

    methods: {
        async configureApplePay() {
            if (window.ApplePaySession) {
                console.log('apple pay is available on device');
                var merchantIdentifier = 'merchant.com.sgnl24.boss.sales-web';
                let canMakePayments = await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
                if (canMakePayments) {
                    this.isApplePayAvailable = true;
                } else {
                    console.log('canMakePayments is false');
                }
            } else console.log('apple pay is not available');
        },

        selectPaymentMethod(method) {
            this.selectedPaymentMethod = method;

            if (method == 'applePay') this.createApplePayRequest();
        },

        async createApplePayRequest() {
            try {
                await this.createApplePayRequest2();
            } catch (err) {
                console.log('apple pay error', err);
            }
        },

        async createApplePayRequest2() {
            console.log('creating request');

            var request = {
                countryCode: 'US',
                currencyCode: 'USD',
                supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
                merchantCapabilities: ['supports3DS'],
                total: { label: 'SkyVentureNH', amount: 0.25 }
            };

            var session = new window.ApplePaySession(3, request);

            session.onvalidatemerchant = async e => {
                try {
                    console.log('validation url', e.validationURL);

                    const response = await this.$http.post('/php-api/sales/authorize-apple-pay', {
                        validationUrl: e.validationURL
                    });

                    session.completeMerchantValidation(response.data);
                } catch (err) {
                    console.log('validation failure', err);
                }
            };

            // session.onpaymentauthorized = async authorizedPayment => {
            //     session.completePayment(1)
            //     // console.log(authorizedPayment);
            // }

            session.oncancel = (...args) => {
                console.log('cancelled', args);
            };

            session.begin();
        },

        getVoucherProducts(voucher) {
            let products = [];
            for (const productId in voucher.included_products) {
                let matchingAttraction = this.config.attractions.find(attraction => {
                    let result = attraction.products.find(p => p.id == productId);
                    if (result) return true;
                });

                products.push(matchingAttraction.products.find(product => product.id == productId));
            }

            return products;
        },

        confirm() {
            // process payment or whatever
            // this.$emit('push');
        }
    }
};
</script>

<style lang="scss">
#booking-checkout {
    @apply flex flex-col;

    .main {
        @apply lg:flex-row-reverse lg:flex lg:space-x-8 lg:space-x-reverse;

        h1 {
            @apply text-2xl text-blue mb-4;

            &:not(:first-child) {
                @apply mt-8;
            }
        }

        .reservation {
            @apply flex-shrink;

            > div {
                @apply bg-white rounded-md p-4 border border-gray-400;

                h2 {
                    @apply text-lg font-semibold mb-2;
                }

                > div {
                    @apply pb-2 border-b border-gray-400;

                    &.voucher {
                        @apply mb-2;
                    }

                    &.product {
                        @apply flex mb-2;
                    }

                    span {
                        @apply block;

                        &.currency {
                            @apply font-bold;
                        }
                    }

                    &.total {
                        @apply flex border-none pb-0;
                    }
                }
            }
        }

        .form {
            @apply flex-1 mt-6 lg:mt-0;

            .header {
                @apply block font-semibold text-2xl text-blue-darker;

                &:not(:first-child) {
                    @apply mt-6;
                }
            }

            .row {
                @apply md:flex md:space-x-4;

                .input {
                    @apply flex-1 mt-3;

                    label {
                        @apply block font-semibold;
                    }

                    input {
                        @apply w-full border bg-white border-gray-400 px-4 py-2 mt-1 rounded-md;
                    }
                }
            }

            .payment-container {
                @apply flex flex-col;

                .card-details {
                    @apply flex-1 flex flex-wrap max-w-md;

                    input {
                        @apply bg-white border border-gray-400 py-2 px-4 rounded-md;
                    }

                    .number {
                        @apply w-full rounded-b-none;
                    }

                    .expiration {
                        @apply w-1/2 rounded-t-none rounded-r-none border-t-0;
                    }

                    .security {
                        @apply w-1/2 rounded-t-none rounded-l-none border-t-0 border-l-0;
                    }
                }
            }
        }
    }

    @screen lg {
        .reservation {
            min-width: 450px;
        }
    }
}

// @supports (-webkit-appearance: -apple-pay-button) {
.apple-pay-button {
    @apply rounded-md;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
}
.apple-pay-button-black {
    -apple-pay-button-style: black;
}
.apple-pay-button-white {
    -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}
// }

// @supports not (-webkit-appearance: -apple-pay-button) {
.apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
    cursor: pointer;
    // -apple-pay-button-type: check-out;
}
.apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
}
.apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
}
.apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
}
// }
</style>
