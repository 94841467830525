<template>
    <div id="root">
        <div v-if="store.globalError" class="root-status global-error" v-text="store.globalError" />
        <router-view v-else />
        <OverlayContainer />
    </div>
</template>

<script lang="ts" setup>
import { OverlayContainer } from '@signal24/vue-foundation';

import { useStore } from './store';

const store = useStore();
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kristi&display=swap');
@import '@fortawesome/fontawesome-pro/css/all.min.css';
@import '@signal24/vue-foundation/dist/vue-foundation.css';
@import './common/styles/tailwind.css';
@import './common/styles/base.scss';
</style>

<style lang="scss">
#root {
    @apply flex justify-center;

    > div:first-child {
        @apply w-full max-w-screen-lg m-1 sm:m-12 bg-white rounded-md py-4 sm:py-6 px-4 sm:px-10;
    }
}
</style>
