<template>
    <div class="number-input">
        <button class="decrement" :disabled="number <= 0" @click="decrement"><i class="fa fa-minus" /></button>

        <input :value="number" type="number" v-autofocus="autofocus !== undefined" data-cy="number" />

        <button class="increment" @click="increment" data-cy="increment"><i class="fa fa-plus" /></button>
    </div>
</template>

<script>
export default {
    props: ['value', 'autofocus'],

    data() {
        return {
            number: this.value
        };
    },

    watch: {
        value(val) {
            this.number = val;
        },
        number(val) {
            console.log(val); //  prettier
            this.$emit('input', this.number);
        }
    },

    methods: {
        decrement() {
            if (this.number <= 0) return;
            this.number--;
        },

        increment() {
            if (isNaN(this.number) || this.number == null || this.number == undefined) this.number = 0;
            this.number++;
        }
    }
};
</script>

<style scoped lang="scss">
.number-input {
    @apply flex shadow-sm;

    button {
        @apply bg-gray-200 p-2 rounded-md focus:z-10 focus:bg-gray-200 border border-gray-400 shadow-none;

        &:hover {
            @apply bg-gray-300 shadow-none;
        }

        &:disabled {
            @apply bg-gray-200 cursor-default text-gray-500;
        }
    }

    input {
        @apply text-center px-2 rounded-none;
        max-width: 45px;
        border: 1px solid #bdbdbd !important;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .decrement {
        @apply rounded-tr-none rounded-br-none border-r-0;
    }

    .increment {
        @apply rounded-tl-none rounded-bl-none border-l-0;
    }
}
</style>
