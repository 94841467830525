export default {
    logo: 'https://i0.wp.com/skyventurenh.com/wp-content/uploads/2015/01/logo1.png?fit=174%2C67&ssl=1',
    businessName: 'SkyVenture New Hampshire',
    ageOfMajority: 18,
    additionalTermsSections: [
        {
            header: 'Physical Condition Requirements',
            terms: [
                'I certify that none of the named participants are pregnant.',
                'I certify that none of the named participants have had a shoulder dislocation.',
                'I certify that none of the named participants weigh greater than 250 lbs.'
            ]
        }
    ],
    agreement: {
        title: `SkyVenture New Hampshire Liability Release Agreement`,
        content: `PLEASE READ THIS DOCUMENT CAREFULLY. BY SIGNING IT, YOU ARE GIVING UP LEGAL RIGHTS.

In consideration for being permitted on SKYVENTURE’s premises and participating in activities conducted by SkyVenture New Hampshire LLC and Surf’s Up LLC., I hereby agree as follows:

ASSUMPTION OF RISK: I agree that I am, and/or my minor child/ward (hereafter “child”) is, voluntarily participating in the activities offered by SKYVENTURE / SURFS UP including but not limited to the use of its equipment, facilities, and premises. I am assuming, on behalf of myself and/or my child, all risk of personal injury, death or disability to me and/or my child that might result from said participation or presence on its premises, and any damage, loss or theft of any personal property which I or my child may incur. I understand that the SkyVenture facility is a skydiving and freefall simulator and has inherent risks including but not limited to vertical winds of up to 200 mph. Surf’s Up is a surfing simulator and has inherent risks including but not limited to strong forceful water and potential injury from the Surfstream machine and/or surfboard/bodyboards, including personal injury and drowning. The Fishpipe is a rotating barrel ride with water and has similar inherent risks to the Surfstream machine. I understand and accept all risks related to these activities.

RELEASE OF LIABILITY: I AGREE ON BEHALF OF MYSELF AND/OR MY MINOR CHILD/WARD and my/their personal representative, successors, heirs, and assigns TO RELEASE SkyVenture New Hampshire, LLC, American Wave Machines, OGO, Nicros and its affiliates, instructors, officers, directors, agents, employees, designers, licensors, and members, as well as the premises owner and tenants, and the owners, manufacturers and installers of the equipment comprising the SKYVENTURE / SURFS UP facility (collectively, the “RELEASEES”) FROM ANY AND ALL LIABILITY, CLAIMS OR CAUSES OF ACTION for personal injury, death, damages or loss to me and/or my child, ALLEGING NEGLIGENCE, that is the RELEASEE’S failure to use reasonable care, or any other claim of liability. This Liability Release Agreement includes, without limitation, injuries, illness, or accidents which may occur as a result of: (a) use of the facility or its improper maintenance, (b) use of any equipment which may malfunction or break, © improper maintenance of any equipment, (d) instruction or supervision while engaging in any SKYVENTURE activity, or (e) slipping and falling while on the premises. I understand that I am voluntarily giving up my right to sue the above-described RELEASEES.

I further grant SkyVenture New Hampshire, LLC the right to photograph, videotape, and/or record me and/or my child and to use my and/or my child’s name, face, likeness, voice and appearance in connection with exhibitions, publicity, advertising, and promotional materials without reservation or limitation. SkyVenture New Hampshire does not allow participants to take photographs or videos of Indoor Surfing.

IF PARTICIPANT IS UNDER AGE EIGHTEEN (18)
I have read the above, been given the opportunity to ask questions about it, considered its effects, understand its content, and agree, on behalf of myself and my child/ward, to the terms as stated above. I agree to further indemnify, defend and hold the RELEASEES harmless from any damages or expenses they incur as a result of any claim or lawsuit brought by my child/ward, including payment of the RELEASEE’S attorney’s fees and costs.

I have read the above, been given the opportunity to ask questions about it, considered its effects, understand its content, and agree to the terms as stated above.`
    }
};
