import { createRouter, createWebHashHistory } from 'vue-router';

import Booking from './modules/booking/Views/Booking.vue';
import Agreement from './modules/waiver/sections/agreement.vue';
import People from './modules/waiver/sections/people.vue';
import Start from './modules/waiver/sections/start.vue';
import Waiver from './modules/waiver/waiver.vue';

const routes = [
    {
        path: '/',
        redirect() {
            location.href = 'https://skyventurenh.com';
            return '';
        }
    },
    {
        path: '/waiver',
        component: Waiver,
        children: [
            {
                path: '',
                component: Start
            },
            {
                path: 'people',
                component: People
            },
            {
                path: 'agreement',
                component: Agreement
            }
        ]
    },
    {
        path: '/booking',
        component: Booking
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router;
