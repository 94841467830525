<template>
    <modal id="booking-credit-attraction-modal" closeOnMaskClick closeX>
        <template v-slot:header
            ><h1>{{ attraction.display_title }}</h1></template
        >

        <div class="message">{{ attractionDescription }}</div>

        <div class="price">
            <span class="number"> {{ attraction.products[0].price }} </span>
            <span class="tag"> per person </span>
        </div>

        <template v-slot:footer>
            <button v-if="this.product" class="destructive" @click="deleteProduct" data-cy="btn-remove">Remove</button>
            <div class="right">
                <number-input :value="quantity" @input="newValue => (quantity = newValue)" class="mr-2" />
                <!-- <input v-model.number="quantity" type="number" v-autofocus/> -->
                <button class="primary" @click="confirm" :disabled="!product && quantity === 0" required data-cy="btn-update">
                    {{ buttonMessage }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import NumberInput from '../Components/NumberInput.vue';

export default {
    components: { NumberInput },

    data() {
        return {
            quantity: 1,
            buttonMessage: 'Add',
            attractionDescription: null
        };
    },

    mounted() {
        if (this.attraction.id == 3) this.attractionDescription = 'Enjoy a 90-second ride in our Fishpipe';
        if (this.attraction.id == 4) this.attractionDescription = '15 minute climbing session on our 24-foot wall';

        if (!this.product) return;
        this.buttonMessage = 'Update';
        this.quantity = this.product.quantity;
    },

    methods: {
        deleteProduct() {
            this.$dismiss({ ...this.product, quantity: 0 });
        },

        confirm() {
            if (this.quantity == null) return;

            this.$dismiss({
                ...this.attraction.products[0],
                quantity: this.quantity
            });
        }
    }
};
</script>
<style lang="scss">
#booking-credit-attraction-modal {
    .vf-modal-content {
        .message {
            @apply mb-4 flex justify-center;
        }

        .price {
            @apply flex flex-col items-center justify-center;

            .number {
                @apply relative leading-none text-5xl;

                &:before {
                    @apply absolute w-0 top-0 left-0 text-xl -ml-4 mt-4;
                    content: '$';
                }
            }

            .discount {
                @apply mt-4 p-2 bg-gray-200 text-sm;
            }
        }
    }
    .vf-modal-footer {
        @apply flex;

        .right {
            @apply flex ml-auto;

            input {
                @apply border border-gray-500 w-20 p-2;
            }
        }
    }

    @screen md {
        .vf-modal-content {
            min-width: 500px;
        }
    }
}
</style>
