<template>
    <form @submit.prevent="proceed">
        <main>
            <div v-for="(person, idx) in session.people" :key="idx" class="person" :data-cy="`person-${idx}`">
                <h4>
                    <template v-if="idx === 0">
                        {{ session.signerType === 'minor' ? 'Parent/Guardian' : 'Adult Participant' }}
                    </template>
                    <template v-else>Minor {{ idx }}</template>
                    Information

                    <button data-cy="btn-remove" type="button" v-if="idx > 1" class="remove" @click.prevent="removePerson(idx)">
                        <i class="fa fa-times"></i>
                    </button>
                </h4>

                <div class="row">
                    <div class="field">
                        <label>First Name</label>
                        <input v-model="person.firstName" type="text" required v-autofocus="idx === 0" data-cy="first-name" />
                    </div>
                    <div class="field">
                        <label>Last Name</label>
                        <input v-model="person.lastName" type="text" required data-cy="last-name" />
                    </div>
                </div>

                <div class="row">
                    <div class="field">
                        <label>Date of Birth</label>
                        <date-selection
                            v-model="person.birthdate"
                            :minYearOffset="idx > 0 ? session.config.ageOfMajority : 105"
                            :maxYearOffset="idx > 0 ? 0 : session.config.ageOfMajority"
                            required
                        />
                    </div>
                </div>

                <div v-if="idx === 0" class="row">
                    <div class="field">
                        <label>Phone Number</label>
                        <input v-model="person.phoneNumber" type="tel" required data-cy="phone-number" />
                    </div>
                    <div class="field">
                        <label>Email</label>
                        <input v-model="person.email" type="email" required data-cy="email" />
                    </div>
                </div>

                <div v-if="idx === 0" class="row">
                    <div class="field">
                        <label>Address</label>
                        <input v-model="person.address" type="text" required data-cy="address" />
                    </div>
                    <div class="field short">
                        <label>ZIP</label>
                        <input
                            v-model="person.zip"
                            type="text"
                            pattern="[0-9]*"
                            required
                            minlength="5"
                            maxlength="5"
                            @input="getZipResults"
                            data-cy="zip"
                        />
                    </div>
                    <div class="field">
                        <label>City/State</label>
                        <select v-model="selectedCityState" data-cy="city-state">
                            <option v-for="result in zipResults" :key="result.id" :value="result">{{ result.city }}, {{ result.state }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="idx === 0" class="action-item-wrapper" :class="{ completed: person.didOptOut }">
                    <div class="checkbox-wrapper">
                        <label for="marketing-optout">
                            <input v-model="person.didOptOut" id="marketing-optout" aria-describedby="marketing-optout-description" type="checkbox" />
                            <p id="marketing-optout-description">
                                I do not wish for SkyVenture New Hampshire to email me occasional promotions. (We do not sell your information!)
                            </p>
                        </label>
                    </div>
                </div>
            </div>

            <button v-if="session.signerType !== 'adult'" type="button" class="default w-full" @click="addMinor" data-cy="btn-add-minor">
                <i class="fal fa-plus mr-2" /> Add Another Minor
            </button>
        </main>
        <footer>
            <button type="submit" class="primary" data-cy="btn-continue">Continue</button>
            <button type="button" class="default" @click="$router.back()">Back</button>
        </footer>
    </form>
</template>

<script lang="ts" setup>
import { showAlert } from '@signal24/vue-foundation';
import axios from 'axios';
import { startOfToday, subYears } from 'date-fns';
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import DateSelection from '../../../components/date-selection.vue';
import { useStore } from '../../../store';

const { session } = useStore();
const router = useRouter();

const zipResults = ref<any>(null);
const selectedCityState = ref<any>(null);

onBeforeMount(() => {
    if (!session.signerType) {
        return router.replace('/waiver');
    }

    if (session.people.length === 0) {
        session.people.push({
            firstName: null,
            lastName: null,
            birthdate: null,
            email: null,
            phoneNumber: null,
            address: null,
            city: null,
            state: null,
            zip: null
        });
    }

    if (session.signerType === 'adult') {
        session.people = session.people.slice(0, 1);
    } else if (session.people.length === 1) {
        addMinor();
    }
});

onMounted(() => {
    session.people[0] && getZipResults();
});

watch(selectedCityState, val => {
    if (!val) return;
    session.people[0].city = val.city;
    session.people[0].state = val.state;
});

async function getZipResults() {
    selectedCityState.value = null;

    const zip = session.people[0].zip;
    if (zip === null) return zip;

    if (zip.length !== 5) {
        zipResults.value = null;
        return null;
    }

    const response = await axios.get('/api/zip?q=' + zip);
    zipResults.value = response.data;
    selectedCityState.value = zipResults.value[0];
}

function addMinor() {
    session.people.push({
        firstName: null,
        lastName: null,
        birthdate: null
    });
}

function removePerson(idx) {
    session.people.splice(idx, 1);
    session.people.length === 1 && addMinor();
}

function proceed() {
    if (!selectedCityState.value) {
        showAlert('Please enter a valid ZIP code.');
        return;
    }

    const today = startOfToday();
    const minorBirthdateCutoff = subYears(today, session.config.ageOfMajority);

    const adultBirthdate = new Date(session.people[0].birthdate);
    if (adultBirthdate > minorBirthdateCutoff) {
        return showAlert('You must be at least 18 years old to fill out this waiver.');
    }

    const minorWithAdultBirthdate = session.people.slice(1).find(person => new Date(person.birthdate) <= minorBirthdateCutoff);
    if (minorWithAdultBirthdate) {
        return showAlert(
            `${minorWithAdultBirthdate.firstName} ${minorWithAdultBirthdate.lastName} is at least ${session.config.ageOfMajority} years old and must fill out his or her own waiver.`
        );
    }

    router.push('/waiver/agreement');
}
</script>

<style lang="scss" scoped>
main {
    @apply space-y-6 mt-6;
}

.person {
    @apply flex flex-col space-y-6 border border-gray-300 rounded-md p-4;

    h4 {
        @apply flex items-center text-gray-600;

        button.remove {
            @apply ml-auto text-lg;

            &:hover {
                @apply bg-gray-300;
            }
        }
    }

    .row {
        @apply sm:flex space-y-4 sm:space-x-3 sm:space-y-0;

        .field {
            @apply flex-1 flex flex-col;

            label {
                @apply block text-sm font-semibold text-gray-700;
            }

            input,
            select {
                @apply mt-1 shadow-sm focus:ring-blue focus:border-blue block sm:text-sm border border-gray-300 rounded-md;
            }

            .multi-input {
                @apply w-full flex space-x-3;

                select {
                    @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm rounded-md;
                }
            }

            &.short {
                @apply w-24 flex-grow-0;
            }
        }
    }
}

.action-item-wrapper {
    @apply p-2 bg-gray-50 border border-gray-300 rounded-md duration-150 space-y-2;

    &.completed {
        @apply bg-red-100 border-red-500;

        h5,
        p {
            @apply text-red-700;
        }

        button.primary {
            @apply bg-red-500 hover:bg-red-600 focus:ring-red-500;
        }
    }

    p {
        @apply text-gray-500 text-sm;
    }
}

#marketing-optout:checked {
    @apply text-red-400 border-red-400 #{!important};
}
</style>
