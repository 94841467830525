<template>
    <VfModal id="signature-modal" closeX>
        <template v-slot:header>
            <h3>Sign</h3>
        </template>

        <canvas ref="canvas" width="300" height="200" />

        <template v-slot:footer>
            <div class="buttons">
                <button class="default" @click="clearSignature">Clear</button>
                <button class="primary" @click="submitSignature" data-cy="btn-submit">Submit</button>
            </div>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { showAlert, VfModal } from '@signal24/vue-foundation';
import SignaturePad from 'signature_pad';
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps<{
    callback: (data: string) => void;
}>();

const canvas = ref<HTMLCanvasElement | null>(null);
const signaturePad = ref<SignaturePad>();

onMounted(() => {
    signaturePad.value = new SignaturePad(canvas.value!, { minWidth: 1, maxWidth: 3 });
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
});

onUnmounted(() => {
    window.removeEventListener('resize', resizeCanvas);
});

function resizeCanvas() {
    // var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.value!.width = canvas.value!.parentElement!.parentElement!.clientWidth - 32;
    canvas.value!.height = 200;
    // this.canvas.getContext('2d').scale(ratio, ratio);
    signaturePad.value!.clear();
}

function clearSignature() {
    signaturePad.value!.clear();
}

function submitSignature() {
    if (signaturePad.value!.isEmpty()) {
        showAlert('Please sign your name.');
        return;
    }

    const data = signaturePad.value!.toDataURL();
    props.callback(data);
}
</script>

<style lang="scss">
#signature-modal .vf-modal {
    @apply w-full max-w-3xl;
}
</style>

<style lang="scss" scoped>
canvas {
    @apply border border-gray-400 rounded-md overflow-hidden my-4 w-full;
}

.buttons {
    @apply flex-1 flex justify-between;
}
</style>
