<template>
    <div id="start">
        <header>
            <h4 data-cy="prompt">Which participants will you be signing for?</h4>
        </header>
        <main>
            <div class="participant-selection">
                <div
                    v-for="(type, idx) in participantTypes"
                    :key="type.key"
                    role="button"
                    :class="{ selected: session.signerType == type.key }"
                    @click="session.signerType = type.key"
                    :data-cy="`participant-type-${type.key}`"
                >
                    <div class="content">
                        <div>
                            <i v-for="icon in type.icons" :key="icon" :class="`fa fa-${icon}`" />
                        </div>
                        <span v-html="type.description" />
                    </div>
                </div>
            </div>
        </main>

        <footer class="text-center">
            <button class="primary" v-disabled="!session.signerType" @click="proceed" data-cy="btn-continue">Continue</button>
        </footer>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '../../../store';

const { session } = useStore();
const router = useRouter();

const participantTypes = computed(() => {
    return [
        {
            key: 'adult',
            icons: ['male'],
            description: `Myself\n(${session.config.ageOfMajority}+ Adult)`
        },
        {
            key: 'minor',
            icons: ['child'],
            description: `Minor Only\n(Under ${session.config.ageOfMajority})`
        },
        {
            key: 'adultAndMinor',
            icons: ['male', 'child smaller'],
            description: `Both`
        }
    ];
});

function proceed() {
    router.push('/waiver/people');
}
</script>

<style lang="scss" scoped>
header {
    @apply text-center;
}

.participant-selection {
    @apply flex flex-col sm:flex-row sm:space-x-12 space-y-4 sm:space-y-0;

    > * {
        @apply flex flex-col border border-gray-400 rounded-lg overflow-hidden w-full hover:bg-blue-lightest duration-150;
        height: 200px;

        > .content {
            @apply flex-1 flex flex-col items-center justify-center py-4 space-y-4 text-center;

            > *:first-child {
                @apply flex items-end;
            }

            i {
                @apply text-7xl mx-1;

                &.smaller {
                    @apply text-5xl;
                }
            }
        }

        &.selected {
            @apply bg-blue text-white;

            i {
                @apply text-white;
            }

            .footer {
                @apply bg-blue text-white;
            }
        }
    }
}
</style>
