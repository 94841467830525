<template>
    <div id="booking-calendar">
        <h1>Availability for</h1>

        <section class="header">
            <h2><span v-datetime="dates.first()" local format="MMM Do" /> &mdash; <span v-datetime="dates.last()" local format="MMM Do" /></h2>
            <div class="arrows">
                <button class="left default" :disabled="isCurrentWeek" @click="viewPreviousWeek">
                    <i class="fas fa-arrow-left" />
                </button>
                <button class="right default" @click="viewNextWeek">
                    <i class="fas fa-arrow-right" />
                </button>
            </div>
        </section>

        <section class="main">
            <div v-if="isLoading" class="loading-indicator">
                <i class="fad fa-spinner-third fa-spin" />

                <span class="message"> Checking availability... </span>
            </div>

            <div v-else-if="!hasAvailability" class="message">No times available, please check different dates or try again later.</div>

            <div v-else class="calendar">
                <div v-for="(value, date, index) in availability" :key="date + index" class="day">
                    <div class="date-container">
                        <div class="date" local v-datetime="date" format="ddd, MMM Do" />
                    </div>

                    <div v-if="!value.length" class="time-slot unavailable">No availability</div>

                    <div
                        v-for="time in value"
                        :key="date + time"
                        class="time-slot"
                        :class="{ selected: selected.date == date && selected.slot[0].firstSlot == time }"
                        @click="handleTimeSelected(date, time)"
                    >
                        {{ getCheckInTime(time) }}
                    </div>
                </div>
            </div>
        </section>

        <section class="footer">
            <button class="back default" @click="$emit('pop')">Back</button>
            <button class="confirm primary" :disabled="!selected.date || !selected.slot" @click="confirm">Continue</button>
        </section>
    </div>
</template>

<script>
import { addDays, format, parse, subDays, subMinutes } from 'date-fns';

export default {
    name: 'Calendar',

    store: ['config', 'booking'],

    data() {
        return {
            isLoading: true,
            dates: [],
            data: null,
            availability: {},
            selected: {
                date: null,
                slot: null
            },
            availabilityRequest: {
                date_start: null,
                date_end: null,
                demand: {
                    attractions: {}
                }
            }
        };
    },

    mounted() {
        this.setDates();
    },

    computed: {
        isCurrentWeek() {
            return this.dates.includes(format(new Date(), 'yyyy-MM-dd'));
        },

        hasAvailability() {
            let someAvailability = false;

            for (let date in this.availability) if (this.availability[date].length) someAvailability = true;

            return someAvailability;
        }
    },

    methods: {
        viewPreviousWeek() {
            if (this.isCurrentWeek) return;

            this.dates[0] = format(subDays(parse(this.dates[0], 'yyyy-MM-dd'), 7), 'yyyy-MM-dd');
            this.setDates();
        },

        viewNextWeek() {
            this.dates[0] = format(addDays(parse(this.dates[0], 'yyyy-MM-dd'), 7), 'yyyy-MM-dd');
            this.setDates();
        },

        setDates() {
            if (!this.dates.length) this.dates.push(format(new Date(), 'yyyy-MM-dd'));

            for (let i = 0; i < 6; i++) {
                this.dates.splice(i + 1, 1, format(addDays(parse(this.dates[i], 'yyyy-MM-dd'), 1), 'yyyy-MM-dd'));
            }

            this.requestAvailability();
        },

        async requestAvailability() {
            this.isLoading = true;
            this.availability = {};

            for (let attraction in this.booking.attractions) {
                if (this.booking.attractions[attraction].type != 'scheduled') continue;

                this.booking.attractions[attraction].products.forEach(product => {
                    if (!this.availabilityRequest.demand.attractions[attraction]);
                    this.availabilityRequest.demand.attractions[attraction] = [];
                    this.availabilityRequest.demand.attractions[attraction].push({
                        product: product.id,
                        quantity: product.quantity
                    });
                });
            }

            this.availabilityRequest.date_start = this.dates.first();
            this.availabilityRequest.date_end = this.dates.last();

            try {
                let result = await this.$http.post('/php-api/booking/find-availability', this.availabilityRequest);
                this.data = result.data;
            } catch (err) {
                this.$alert('err', err);
            }

            this.configureCalendar();
        },

        configureCalendar() {
            for (let date in this.data) {
                let startSlots = [];
                this.data[date].slots.forEach(slot => {
                    let time = slot[0].firstSlot;
                    if (!startSlots.includes(time)) {
                        startSlots.push(time);
                    }
                });

                startSlots.sort((a, b) => a.localeCompare(b));
                this.availability[date] = startSlots.map(slot => slot);
            }

            this.isLoading = false;
        },

        getCheckInTime(time) {
            return format(subMinutes(parse(time, 'HHmm'), this.config.check_in_minutes), 'h:mm a');
        },

        handleTimeSelected(date, time) {
            console.log(date, time);

            this.selected = {
                date: date,
                slot: this.data[date].slots.find(slot => slot[0].firstSlot == time)
            };
        },

        confirm() {
            this.booking.selected = this.selected;
            this.$emit('push');
        }
    }
};
</script>

<style lang="scss">
#booking-calendar {
    @apply flex flex-col;

    .header {
        @apply flex items-center text-2xl;

        .arrows {
            @apply flex ml-auto;

            button {
                @apply flex flex-row items-center justify-center px-4 py-3 border bg-white border-blue text-blue text-base;

                &:disabled {
                    @apply text-blue-lightest border-blue-lightest cursor-default;
                }

                &:not(:disabled):hover {
                    @apply bg-blue border-blue-lighter text-white;
                }

                &:not(:disabled):focus {
                    @apply border-blue-lighter ring-2 z-10;
                }

                &.left {
                    @apply border-r-0 rounded-tr-none rounded-br-none;
                }

                &.right {
                    @apply rounded-tl-none rounded-bl-none;
                }
            }
        }
    }

    .main {
        @apply flex-1 flex mt-8 min-h-0;

        > .message {
            @apply flex-1 flex items-center justify-center;
        }

        .calendar {
            @apply flex-1 flex overflow-auto space-x-2;

            .day {
                @apply flex-1 flex-shrink-0 relative;
                min-width: 140px;

                .date-container {
                    @apply bg-gray-100 pb-2 sticky top-0;

                    .date {
                        @apply bg-white p-2 rounded-md border border-blue text-center;
                    }
                }

                .time-slot {
                    @apply mb-2 text-white rounded-md p-2 text-center;

                    &:not(.unavailable) {
                        @apply bg-blue-lighter  cursor-pointer shadow-sm duration-150;

                        &:hover {
                            @apply bg-blue;
                        }

                        &.selected {
                            @apply bg-blue-darker shadow-md;
                        }
                    }

                    &.unavailable {
                        @apply bg-blue-lightest cursor-not-allowed;
                    }
                }
            }
        }
    }
}
</style>
