import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './app.vue';
import router from './router';
import { setupVf } from './vf.setup';

const app = createApp(App);

Sentry.init({
    app,
    dsn: 'https://084e68e379b34a658045caba75b238e1@sentry.s24.io/7',
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['cx.skyventurenh.com', /^\//]
        })
    ]
});

setupVf(app);

const pinia = createPinia();
app.use(pinia);

app.use(router);

app.mount('#app');
