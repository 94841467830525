<template>
    <div id="waiver">
        <AppHeader title="Electronic Waiver" />
        <div class="wrapper">
            <div v-if="!store.session?.config" class="wrapper loading">Loading...</div>
            <router-view v-else />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, watch } from 'vue';

import AppHeader from '../../components/Header.vue';
import { useStore } from '../../store';
import staticConfig from './config.js';

const store = useStore();

onMounted(async () => {
    loadSession();
    store.session || setupDefaultSession();
    store.session.resvNum = getResvNum();
    store.session.config = null;
    store.session.config = await getConfig();
    watch(
        () => store.session,
        () => persistSession(),
        { deep: true }
    );
});

function loadSession() {
    const sessionJson = window.localStorage.getItem('session');
    if (!sessionJson) return;
    try {
        const timeoutMs = (process.env.NODE_ENV === 'development' ? 5 * 60 : 30) * 1000;
        const session = JSON.parse(sessionJson);
        if (session.ts < Date.now() - timeoutMs) {
            window.localStorage.removeItem('session');
        } else {
            store.session = session;
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
}

function persistSession() {
    if (store.session) {
        window.localStorage.setItem(
            'session',
            JSON.stringify({
                ts: Date.now(),
                ...store.session
            })
        );
    } else {
        window.localStorage.removeItem('session');
    }
}

function setupDefaultSession() {
    store.session = {
        signerType: null,
        people: []
    };
}

function getResvNum() {
    return location.href.match(/[?&]resv=(\d+)/)?.[1] || null;
}

async function getConfig() {
    return staticConfig;
}
</script>

<style lang="scss">
#waiver {
    .wrapper {
        &.loading {
            @apply p-24 text-center text-2xl;
        }

        header {
            @apply pb-8;
        }

        > div {
            @apply flex flex-col mt-8;
        }

        footer {
            @apply py-4;

            button:not(:first-of-type) {
                @apply ml-2;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-semibold;
    }

    h3 {
        @apply text-2xl sm:text-3xl;
    }

    h4 {
        @apply text-xl sm:text-2xl;
    }

    .checkbox-wrapper {
        @apply relative flex items-center;

        label {
            @apply flex-1 flex cursor-pointer;
            -webkit-tap-highlight-color: transparent;
        }

        input {
            @apply focus:ring-blue h-6 w-6 text-green-600 border-gray-300 rounded mr-3;
            -webkit-tap-highlight-color: transparent;

            &:checked {
                @apply focus:ring-green-500;
            }
        }

        .aria-label {
            @apply flex font-medium text-gray-700;
        }
    }
}
</style>
