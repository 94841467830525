<template>
    <div id="booking-participants">
        <section class="main">
            <div class="loading-indicator" v-if="isLoading">
                <i class="fas fa-spin fa-spinner"></i>
                <span class="description"> Please wait while we process and reserve your selection </span>
            </div>

            <template v-else>
                <span class="header"> Please enter participant information for each activity </span>

                <div v-for="(attraction, id) in booking.attractions" :key="'attraction' + id">
                    <h2>
                        {{ attraction.display_title }}
                        <template v-if="attraction.type == 'scheduled'">
                            &mdash; <span v-datetime="getTime(id)" local format="MMM Do h:mm a" />
                        </template>
                    </h2>

                    <div v-for="(product, idx) in attraction.products" :key="'product' + idx">
                        <div class="flex space-x-4 mt-4">
                            <div class="input">
                                <label>First Name</label>
                                <input type="text" />
                            </div>

                            <div class="input">
                                <label>Last Name</label>
                                <input type="text" />
                            </div>

                            <div class="input">
                                <label>Date of Birth</label>
                                <input type="text" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </section>

        <section v-if="!isLoading" class="footer flex justify-end space-x-4 px-4">
            <button class="back default" @click="$emit('pop')">Back</button>
            <button class="confirm primary" @click="confirm">Contiue</button>
        </section>
    </div>
</template>

<script>
export default {
    store: ['config', 'booking'],

    data() {
        return {
            isLoading: true,
            currentActivityName: 'tunnel',
            currentActivity: null
        };
    },

    watch: {
        booking: {
            deep: true,
            handler() {
                console.log('booking object has changed');
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.isLoading = !this.isLoading;
        }, 1000);

        this.getTime(1);
    },

    methods: {
        getTime(attractionId) {
            let date = this.booking.selected.date;
            let time = this.booking.selected.slot.find(s => s.attractionId == attractionId).firstSlot.replace(/(\d\d?)(\d\d)/, '$1:$2');

            console.log('time is', time);
            return date + ' ' + time;
        },
        confirm() {
            this.$emit('push');
        }
    }
};
</script>

<style lang="scss">
#booking-participants {
    @apply flex-1 flex flex-col min-h-0 m-auto;

    .loading-indicator {
        @apply flex flex-col;
    }

    .main {
        @apply flex-1 p-8;

        .header {
            @apply block text-2xl text-blue mb-6;
        }

        > div {
            @apply mb-6 p-2;

            h2,
            h2 > span {
                @apply text-lg font-medium;
            }

            .input {
                label {
                    @apply font-medium;
                }

                input {
                    @apply mt-1;
                }
            }
        }
    }
}
</style>
