<template>
    <form @submit.prevent="submitWaiver">
        <main>
            <div class="section" data-cy="esign-authorization">
                <h4>Electronic Signature Authorization</h4>
                <p data-cy="authorization">
                    I understand and acknowledge that if I proceed to register online and to sign the waiver electronically, that, under the
                    Electronic Transactions Act, such electronic registration and electronically signed Waiver document will be valid and enforced in
                    the same manner as a hand-signed document that exists in physical form and that a record or signature may not be denied legal
                    effect or enforceability under law solely because it is in electronic form.
                </p>
                <div class="action-item-wrapper" :class="{ completed: hasAcceptedEsign }">
                    <div class="checkbox-wrapper">
                        <label for="esign-agree">
                            <input v-model="hasAcceptedEsign" id="esign-agree" aria-describedby="esign-agree-description" type="checkbox" />
                            <p id="esign-agree-description">I understand, agree and acknowledge the previous paragraph.</p>
                        </label>
                    </div>
                </div>
            </div>

            <div v-for="(person, idx) in people" :key="idx" class="section" :data-cy="`person-${idx}`">
                <h4>
                    <template v-if="idx === 0">
                        {{ session.signerType === 'minor' ? 'Parent/Guardian' : 'Adult Participant' }}
                    </template>
                    <template v-else>Minor {{ idx }}</template>
                    Information
                </h4>

                <div class="field">
                    <label>First Name</label>
                    <span data-cy="first-name">{{ person.firstName }}</span>
                </div>

                <div class="field">
                    <label>Last Name</label>
                    <span data-cy="last-name">{{ person.lastName }}</span>
                </div>

                <div class="field">
                    <label>Date of Birth</label>
                    <span data-cy="dob">{{ person.birthdate }}</span>
                </div>

                <template v-if="idx === 0">
                    <div class="field">
                        <label>Phone Number</label>
                        <span data-cy="phone">{{ $filters.phone(person.phoneNumber) }}</span>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <span data-cy="email">{{ person.email }}</span>
                    </div>

                    <div class="field">
                        <label>Address</label>
                        <span data-cy="address">{{ person.address }}, {{ formatCity(person.city) }}, {{ person.state }} {{ person.zip }}</span>
                    </div>
                </template>
            </div>

            <div v-for="(section, i) in session.config.additionalTermsSections" :key="i" class="section" :data-cy="`terms-section-${i}`">
                <h4>{{ section.header }}</h4>

                <div class="space-y-4">
                    <div v-for="(term, k) in section.terms" :key="k" class="action-item-wrapper" :class="{ completed: termToggles[`${i}-${k}`] }">
                        <div class="checkbox-wrapper">
                            <label :for="`term-${i}-${k}`">
                                <input
                                    v-model="termToggles[`${i}-${k}`]"
                                    :id="`term-${i}-${k}`"
                                    :name="`term-${i}-${k}`"
                                    :aria-describedby="`term-${i}-${k}-description`"
                                    type="checkbox"
                                    required
                                />
                                <p class="aria-label" :id="`term-${i}-${k}-description`">{{ term }}</p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" data-cy="liability-release">
                <h4>{{ session.config.agreement.title }}</h4>
                <pre data-cy="agreement">{{ session.config.agreement.content }}</pre>
                <div class="action-item-wrapper" :class="{ completed: hasAcceptedWaiver }">
                    <div class="checkbox-wrapper">
                        <label for="waiver-agree">
                            <input v-model="hasAcceptedWaiver" id="waiver-agree" aria-describedby="waiver-agree-description" type="checkbox" />
                            <p id="waiver-agree-description">I accept the {{ session.config.agreement.title }}.</p>
                        </label>
                    </div>
                </div>
            </div>

            <div class="section" data-cy="signature">
                <h4>Signature</h4>
                <p>
                    By signing below and clicking the “Submit {{ people.length === 1 ? 'Waiver' : 'Waivers' }}” button below, I indicate my acceptance
                    and delivery of this waiver and release. I acknowledge that I have been given an opportunity to prevent or correct any error in
                    connection with this waiver form. If I have submitted this waiver form in error, I will immediately notify you of the error,
                    revoke my signature as instructed, and refrain from participating in any event or activity to which the waiver applies, as
                    provided in Section 204(b) of the Uniform Electronic Transactions Act.
                </p>

                <div class="signature-wrapper">
                    <button v-if="!hasSigned" type="button" class="primary" @click="applySignature" data-cy="btn-sign">
                        {{ supportsTouch ? 'Sign Now' : 'Apply Signature' }}
                    </button>
                    <img v-else-if="signatureData" :src="signatureData" />
                    <span v-else class="e-signature">{{ people[0].firstName }} {{ people[0].lastName }}</span>
                </div>
            </div>

            <div class="action-item-wrapper" :class="{ completed: shouldEmailCopy }">
                <div class="checkbox-wrapper">
                    <label for="waiver-send">
                        <input v-model="shouldEmailCopy" id="waiver-send" aria-describedby="waiver-send-description" type="checkbox" />
                        <p id="waiver-send-description">E-mail me a copy of the signed waiver</p>
                    </label>
                </div>
            </div>
        </main>

        <footer>
            <button type="submit" class="primary" v-disabled="!isReadyToSubmit" data-cy="btn-submit">
                Submit {{ people.length === 1 ? 'Waiver' : 'Waivers' }}
            </button>
            <button type="button" class="default" @click="$router.replace('/waiver/people')" data-cy="btn-back">Back</button>
        </footer>
    </form>
</template>

<script lang="ts" setup>
import { handleErrorAndAlert, presentOverlay, showAlert, showWait } from '@signal24/vue-foundation';
import axios from 'axios';
import { startCase } from 'lodash';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import SignatureModal from '../../../components/signature-modal.vue';
import { useStore } from '../../../store';

const store = useStore();
const { session } = store;

const router = useRouter();

const termToggles = ref<any[]>([]);
const hasAcceptedEsign = ref(false);
const hasAcceptedWaiver = ref(false);
const hasSigned = ref(false);
const signatureData = ref<any>(null);
const shouldEmailCopy = ref(false);

const supportsTouch = computed(() => {
    return true; // 'ontouchstart' in window || 'msMaxTouchPoints' in navigator;
});

const people = computed(() => {
    return session.people;
});

const isReadyToSubmit = computed(() => {
    return (
        hasAcceptedEsign.value &&
        hasAcceptedWaiver &&
        hasSigned.value &&
        session.config.additionalTermsSections.every((section, sectionIdx) =>
            section.terms.every((_, termIdx) => termToggles.value[`${sectionIdx}-${termIdx}`])
        )
    );
});

onBeforeMount(() => {
    if (!session.signerType) return router.replace('/waiver');
    if (!session.people.length) return router.replace('/waiver/people');
});

function formatCity(city: string) {
    return startCase(city.toLowerCase());
}

async function applySignature() {
    if (supportsTouch.value) {
        const result = await presentOverlay(SignatureModal, {});
        if (!result) return;
        signatureData.value = result;
        hasSigned.value = true;
    } else {
        hasSigned.value = true;
    }
}

async function submitWaiver() {
    const unwait = showWait('Submitting...');

    try {
        await axios.post('/api/waiver/submit', {
            config: session.config,
            people: session.people,
            signerType: session.signerType,
            signatureData: signatureData.value,
            shouldEmailCopy: shouldEmailCopy.value,
            didOptOut: !!session.people[0].didOptOut,
            resvNum: session.resvNum
        });
    } catch (err) {
        return handleErrorAndAlert(err);
    } finally {
        unwait();
    }

    await showAlert(`Your ${people.value.length === 1 ? 'waiver has' : 'waivers have'} been submitted. Thank you.`);
    store.session = {
        config: session.config,
        signerType: null,
        people: []
    };

    router.replace('/waiver');

    if ((window as any).webkit?.messageHandlers?.webWrapper) {
        (window as any).webkit.messageHandlers.webWrapper.postMessage('waiverCompleted');
    } else if (window.opener) {
        window.close();
    }
}
</script>

<style lang="scss" scoped>
main {
    @apply space-y-6;

    h4 {
        @apply mb-2;
    }

    pre {
        @apply block p-6 bg-gray-200 rounded-md text-sm sm:text-base whitespace-pre-wrap max-h-96 overflow-y-scroll;
    }
}

.section {
    @apply flex flex-col space-y-2 border border-gray-300 rounded-md p-4;

    &:first-child {
        @apply mt-8;
    }

    h4 {
        @apply text-gray-600;
    }

    .field {
        @apply sm:flex space-y-2 sm:space-x-1 sm:space-y-0 items-center;

        label {
            @apply font-semibold text-gray-700;
            width: 150px;
        }

        span {
            @apply flex-1 shadow-sm block bg-gray-100 rounded-md p-1 p-2;
        }
    }
}

.action-item-wrapper {
    @apply p-4 bg-blue-lightest border border-blue rounded-md duration-150 space-y-2;

    &.completed {
        @apply bg-green-100 border-green-500;

        h5,
        p {
            @apply text-green-700;
        }

        button.primary {
            @apply bg-green-500 hover:bg-green-600 focus:ring-green-500;
        }
    }

    h5,
    p {
        @apply text-blue-darker;
    }
}

.signature-wrapper {
    @apply pt-2;

    img {
        max-width: 500px;
    }
}

.e-signature {
    @apply text-4xl;
    font-family: 'Kristi', cursive;
}

:deep(.mask) {
    @apply absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center text-3xl text-white;
}
</style>
