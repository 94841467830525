<template>
    <modal id="booking-package-modal" closeX closeOnMaskClick scrolls>
        <template v-slot:header><h1>Combo Packages</h1></template>
        <div class="message" v-html="message"></div>
        <div class="packages">
            <div v-for="(aPackage, idx) in packages" :key="aPackage.id" class="package" :data-cy="`package-${idx}`">
                <div class="header">
                    {{ aPackage.display_title }}
                    <span v-if="aPackage.tagline" class="subheader"> {{ aPackage.tagline }} </span>
                </div>
                <div class="content">
                    <div class="price">
                        <span class="number"> {{ aPackage.price }} </span>
                        <span class="tag"> per person </span>
                    </div>
                </div>

                <div class="footer">
                    <number-input :value="aPackage.quantity" class="mr-2" @input="updatePackageSelection($event, aPackage)" />
                    <button
                        class="primary"
                        :disabled="!checkIfExistsInBooking(aPackage) && !aPackage.quantity"
                        @click="handleComboPackageUpdate(aPackage)"
                        data-cy="btn-update"
                    >
                        {{ checkIfExistsInBooking(aPackage) ? 'Update' : 'Add' }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import NumberInput from '../Components/NumberInput.vue';

export default {
    components: { NumberInput },

    data() {
        return {
            message: null,
            packages: []
        };
    },

    mounted() {
        this.config.packages.forEach(pkg => {
            let applied = this.booking.vouchers.find(voucher => voucher.id == pkg.id);
            this.packages.push({ ...pkg, quantity: applied ? applied.quantity : 0 });
        });
    },

    methods: {
        updatePackageSelection(e, aPackage) {
            aPackage.quantity = e;
        },

        handleComboPackageUpdate(aPackage) {
            let updated = this.packages.find(voucher => voucher.id == aPackage.id);
            this.$dismiss(updated);
        },

        associatedVoucher(aPackage, property) {
            let voucher = this.booking.vouchers.find(voucher => voucher.id == aPackage.id);
            if (!voucher) return 0;
            if (!property) return voucher;

            return voucher[property];
        },

        checkIfExistsInBooking(aPackage) {
            return this.booking.vouchers.find(x => x.id == aPackage.id);
        }
    }
};
</script>
<style lang="scss">
#booking-package-modal {
    .vf-modal {
        @apply max-w-4xl;
    }

    .message {
        @apply p-6 pt-2 flex items-center justify-center text-center;
    }

    .packages {
        @apply justify-center justify-around flex;

        .package {
            @apply flex flex-col w-full border border-blue m-2 h-64;

            .header {
                @apply flex flex-col items-center justify-center text-center p-2 bg-blue text-white text-xl h-16;
            }

            .content {
                @apply flex flex-1 justify-center items-center;

                .price {
                    @apply flex flex-col items-center justify-center;

                    .number {
                        @apply relative leading-none text-5xl;

                        &:before {
                            @apply absolute w-0 top-0 left-0 text-xl -ml-4 mt-4;
                            content: '$';
                        }
                    }
                }
            }

            .footer {
                @apply flex px-3 pb-3 justify-between lg:justify-end;

                input {
                    @apply border border-gray-400 w-16 p-2;
                }

                button {
                    @apply border;

                    &.primary {
                        @apply min-w-24;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 660px) {
        .close {
            @apply m-6;
        }

        .packages {
            @apply flex-col max-w-lg m-auto;

            .package {
                @apply m-0 mb-2 h-auto;

                .header {
                    @apply text-lg;
                }

                .price {
                    @apply my-4;

                    .number {
                        font-size: 32px !important;

                        &:before {
                            font-size: 24px !important;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        .packages .package .header {
            font-size: 18px !important;
        }
    }
}
</style>
